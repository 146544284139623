.back {
    display: inline-block;
    margin-left: 2px;
    margin-top: 24px;
}
//
// Breadcrumbs with the symbol coming after the crumb so that mobile looks beter
// --------------------------------------------------
.after-breadcrumb {
    padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
    margin-bottom: @line-height-computed;
    list-style: none;
    background-color: @breadcrumb-bg;
    border-radius: @border-radius-base;



    > li {
        display: inline-block;
    }
    li:after {
            //content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
            content: "@{breadcrumb-separator}"; // Unicode space added since inline-block means non-collapsing white-space
            padding: 0 5px;
            color: #337ab7;/* @breadcrumb-color;*/
    }
    li:last-child:after {
        content:"";
    }

    > .active {
        color: @breadcrumb-active-color;
    }
}

@media ( max-width: 480px ) {
    .backcrumb {
        display:inline-block;
    }
    .after-breadcrumb {
        display:none;
    }
}
@media ( min-width: 479px ) {
    .backcrumb {
        display:none;
    }
    .after-breadcrumb {
        display:block;
    }
}

.backcrumb {
    /*margin-left: 15.555px;*/
    /*margin-left: 8px;*/
    margin-left: 2px;
    margin-top: 12px;
    /*margin-bottom: -36px;*/
}

