@font-face {
  font-family: 'fontello';
      src: url('../cdn/media/fontello/fontello.eot?32330455');
      src: url('../cdn/media/fontello/fontello.eot?32330455#iefix') format('embedded-opentype'),
           url('../cdn/media/fontello/fontello.woff?32330455') format('woff'),
           url('../cdn/media/fontello/fontello.ttf?32330455') format('truetype'),
           url('../cdn/media/fontello/fontello.svg?32330455#fontello') format('svg');
      font-weight: normal;
      font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
  
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
  
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  
  .icon-link:before { content: '\e800'; } /* '' */
  .icon-confusion:before { content: '\f100'; } /* '' */
  .icon-minus:before { content: '\f101'; } /* '' */
  .icon-loud-speaker:before { content: '\f102'; } /* '' */
  .icon-signature:before { content: '\f103'; } /* '' */
  .icon-sand-clock:before { content: '\f104'; } /* '' */
  .icon-text:before { content: '\f105'; } /* '' */
  .icon-card:before { content: '\f106'; } /* '' */
  .icon-finger:before { content: '\f107'; } /* '' */
  .icon-man:before { content: '\f108'; } /* '' */
  .icon-eye:before { content: '\f109'; } /* '' */
  .icon-mouth:before { content: '\f10a'; } /* '' */
  .icon-hand:before { content: '\f10b'; } /* '' */
  .icon-angry:before { content: '\f10c'; } /* '' */
  .icon-brain:before { content: '\f10d'; } /* '' */
  .icon-talking:before { content: '\f10e'; } /* '' */
  .icon-choose:before { content: '\f10f'; } /* '' */
  .icon-toys:before { content: '\f110'; } /* '' */
  .icon-mortarboard:before { content: '\f111'; } /* '' */
  .icon-hand-wash:before { content: '\f112'; } /* '' */
  .icon-running:before { content: '\f113'; } /* '' */
  .icon-teen:before { content: '\f114'; } /* '' */
  .icon-friendship:before { content: '\f115'; } /* '' */
  .icon-planning:before { content: '\f116'; } /* '' */
  .icon-mom:before { content: '\f117'; } /* '' */
  .icon-happy:before { content: '\f118'; } /* '' */
  