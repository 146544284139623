@page {
    size: auto; /* auto is the initial value */
    margin: 20px; /* this affects the margin in the printer settings */
}

@media print {
    a[href]:after {
        content: none !important;
    }

    * {
      border-color: #000 !important;
    }

    .print {
      display: block !important;
    }

    .noprint {
        visibility: hidden;
    }

    .print-border {
        border: 1px solid #000 !important;
    }

    .print-border-bottom-0 {
        border-bottom: 0 !important;
    }

    .print-border-bottom-1 {
        border-bottom: 1px solid #000 !important;
    }
}
