.btn-arrow-right,
.btn-arrow-left {
    position: relative;
    padding-left: 18px;
    padding-right: 18px;
}
.btn-arrow-right {
    padding-left: 36px;
}
//.btn-arrow-left {
//    padding-right: 36px;
//}
.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before {
//.btn-arrow-left:after { /* make two squares (before and after), looking similar to the button */
    content:"";
    position: absolute;
    top: 5px; /* move it down because of rounded corners */
    width: 22px; /* same as height */
    height: 22px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0px 4px 0px 0px; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0px 4px 0px 0px;
    -moz-border-radius: 0px 4px 0px 0px;
}
.btn-arrow-right:before,
.btn-arrow-right:after {
    transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.btn-arrow-left:before {
//.btn-arrow-left:after {
    transform: rotate(225deg); /* rotate left arrow squares 225 deg to point left */
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
}
.btn-arrow-right:before,
.btn-arrow-left:before { /* align the "before" square to the left */
    left: -11px;
}
.btn-arrow-right:after,
.btn-arrow-left:after { /* align the "after" square to the right */
    right: -11px;
}
.btn-arrow-right:after,
.btn-arrow-left:before { /* bring arrow pointers to front */
    z-index: 1;
}
.btn-arrow-right:before,
.btn-arrow-left:after {/* hide arrow tails background */
    background-color: white;
}
///////////////////////////////////
.btn-arrow-right.btn-xs,
.btn-arrow-left.btn-xs {
    position: relative;
    padding-left: 18*12/14px;
    padding-right: 18*12/14px;
}
.btn-arrow-right.btn-xs {
    padding-left: 36*12/14px;
}
//.btn-arrow-left.btn-xs {
//    padding-right: 36*12/14px;
//}
.btn-arrow-right.btn-xs:before,
//.btn-arrow-right.btn-xs:after,
.btn-arrow-left.btn-xs:before
//.btn-arrow-left.btn-xs:after
{ /* make two squares (before and after), looking similar to the button */
    content:"";
    position: absolute;
    top: 20/2 - (20/sqrt(2px)/2); /* move it down because of rounded corners */
    width: 20/sqrt(2px); /* same as height */
    height: 20/sqrt(2px); /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0px 4*12/14px 0px 0px; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0px 4*12/14px 0px 0px;
    -moz-border-radius: 0px 4*12/14px 0px 0px;
}
.btn-arrow-right.btn-xs:before {
//.btn-arrow-right.btn-xs:after {
    transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.btn-arrow-left.btn-xs:before {
//.btn-arrow-left.btn-xs:after {
    transform: rotate(225deg); /* rotate left arrow squares 225 deg to point left */
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
}
.btn-arrow-right.btn-xs:before,
.btn-arrow-left.btn-xs:before { /* align the "before" square to the left */
    left: -6px;//pulled 6 from just looking
}
//.btn-arrow-right.btn-xs:after,
//.btn-arrow-left.btn-xs:after { /* align the "after" square to the right */
//    right: -7px;//pulled 7 from just looking
//}
//.btn-arrow-right.btn-xs:after,
.btn-arrow-left.btn-xs:before { /* bring arrow pointers to front */
    z-index: 1;
}
.btn-arrow-right.btn-xs:before {
//.btn-arrow-left.btn-xs:after { /* hide arrow tails background */
    background-color: white;
}