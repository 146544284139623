// Core variables and mixins
@import "../../node_modules/bootstrap/less/variables.less";
@import "../../node_modules/bootstrap/less/mixins.less";

// Reset and dependencies
@import "../../node_modules/bootstrap/less/normalize.less";
@import "../../node_modules/bootstrap/less/print.less";
@import "../../node_modules/bootstrap/less/glyphicons.less";

// Core CSS
@import "../../node_modules/bootstrap/less/scaffolding.less";
@import "../../node_modules/bootstrap/less/type.less";
@import "../../node_modules/bootstrap/less/code.less";
@import "../../node_modules/bootstrap/less/grid.less";
@import "../../node_modules/bootstrap/less/tables.less";
@import "../../node_modules/bootstrap/less/forms.less";
@import "../../node_modules/bootstrap/less/buttons.less";

// Components
@import "../../node_modules/bootstrap/less/component-animations.less";
@import "../../node_modules/bootstrap/less/dropdowns.less";
@import "../../node_modules/bootstrap/less/button-groups.less";
@import "../../node_modules/bootstrap/less/input-groups.less";
@import "../../node_modules/bootstrap/less/navs.less";
@import "../../node_modules/bootstrap/less/navbar.less";
@import "../../node_modules/bootstrap/less/breadcrumbs.less";
@import "../../node_modules/bootstrap/less/pagination.less";
@import "../../node_modules/bootstrap/less/pager.less";
@import "../../node_modules/bootstrap/less/labels.less";
@import "../../node_modules/bootstrap/less/badges.less";
@import "../../node_modules/bootstrap/less/jumbotron.less";
@import "../../node_modules/bootstrap/less/thumbnails.less";
@import "../../node_modules/bootstrap/less/alerts.less";
@import "../../node_modules/bootstrap/less/progress-bars.less";
@import "../../node_modules/bootstrap/less/media.less";
@import "../../node_modules/bootstrap/less/list-group.less";
@import "../../node_modules/bootstrap/less/panels.less";
@import "../../node_modules/bootstrap/less/responsive-embed.less";
@import "../../node_modules/bootstrap/less/wells.less";
@import "../../node_modules/bootstrap/less/close.less";

// Components w/ JavaScript
@import "../../node_modules/bootstrap/less/modals.less";
@import "../../node_modules/bootstrap/less/tooltip.less";
@import "../../node_modules/bootstrap/less/popovers.less";
@import "../../node_modules/bootstrap/less/carousel.less";

// Utility classes
@import "../../node_modules/bootstrap/less/utilities.less";
@import "../../node_modules/bootstrap/less/responsive-utilities.less";
@import "../../node_modules/bootstrap/less/variables.less";

@import "./after-breadcrumbs";
@import "./arrow-button";
@import "./panel-list";

@grid-float-breakpoint: @screen-md;
@breadcrumb-separator: ">";

body {
    font-family: 'Montserrat', sans-serif;
}
.progress-bar-0-percent {
    color: #43bea2;
    letter-spacing: .1em;
}
.navbar-collapse.navbar-tall {
    max-height: calc(100vh - 50px);
    overflow-y:scroll;
}
@media (max-width: @screen-md) {
    .navbar-nav > .dropdown > .dropdown-menu.account-menu {

        > li.divider {
            background-color: white;
        }
        > li {
            > a, i {
                color: white;
            }
            > a:hover {
                color: lightgray;
            }
        }
    }
}
button.min-width {
    min-width: 5em;
}

nav.nav-bar-no-border {
    border: none;
}

