
//this should probably be renamed to a more general file name
.panel-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.panel-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.panel-list {
    max-height: 10.7em;
    overflow: auto;

}

div.training-progress {
    margin-bottom: 0;
}
